<template>
  <div class="departmentManage">
    <el-tree :data="catalogue"
             :highlight-current="true"
             default-expand-all
             node-key="id"
             :expand-on-click-node="false"
             :render-content="renderContent"></el-tree>
    <div class="right-box">
      <div>
        <span>组织基本信息</span>
        <el-form :model="departmentMessage"
                 class="department-box"
                 ref="departmentMessage">
          <el-form-item label="部门名称：">{{departmentMessage.partmentName}}</el-form-item>
          <el-form-item label="部门负责人：">{{departmentMessage.partmentManager}}--{{departmentMessage.moblie}}</el-form-item>
          <el-form-item label="简介：">{{departmentMessage.partmentDescription}}</el-form-item>
        </el-form>
      </div>
      <el-button type="success"
                 v-show="departmentMessage.partmentId"
                 @click="addMember"
                 icon="el-icon-plus"
                 class="button-box">添加成员</el-button>
      <el-table :data="memberTable">
        <el-table-column prop="mname"
                         align="center"
                         width="130px"
                         label="用户名"></el-table-column>
        <el-table-column prop="rolename"
                         align="center"
                         label="角色"></el-table-column>
        <el-table-column prop="job"
                         align="center"
                         label="职务"></el-table-column>
        <el-table-column prop="state"
                         align="center"
                         label="员工状态"></el-table-column>
        <el-table-column prop="mobile"
                         align="center"
                         label="手机号"></el-table-column>
        <el-table-column label="操作"
                         align="center">
          <template slot-scope="scope">
            <el-button type="danger"
                       @click="deletesTeacher(scope.$index,scope.row)">删 除
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <!-- 添加成员 -->
    <el-dialog :visible.sync="addNumberVisible"
               width="700px"
               @close="dialogClose"
               title="添加成员">
      <el-input v-model="teacher"
                @input="loadAllTeacher"
                class="input-box"></el-input>
      <div class="dialog-box">
        <div v-for="teacher in teacherList"
             :key="teacher.id"
             class="teacher-box">
          <div>{{teacher.name}}--{{teacher.mobile}}</div>
          <el-button @click="addNumberClick(teacher.id)"
                     class="button-box"
                     type="text">添 加</el-button>
        </div>
      </div>
    </el-dialog>
    <!-- 添加部门 -->
    <el-dialog :visible.sync="addPartmentVisible"
               width="600px"
               :title="addPartmentForm.partmentId ? '修改部门' : '添加部门'">
      <el-form :model="addPartmentForm"
               ref="addPartmentForm"
               label-width="100px">
        <el-form-item label="部门名称"
                      prop="partmentName">
          <el-input v-model="addPartmentForm.partmentName"></el-input>
        </el-form-item>
        <el-form-item label="部门负责人"
                      prop="partmentManager">
          <el-select v-model="addPartmentForm.partmentManager"
                     filterable
                     value-key="id">
            <el-option v-for="teacher in teacherList"
                       :key="teacher.id"
                       :label="teacher.name"
                       :value="{ name: teacher.name, id: teacher.id }"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="部门描述"
                      prop="partmentDescription">
          <el-input v-model="addPartmentForm.partmentDescription"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer">
        <el-button @click="addPartmentVisible = false">取 消</el-button>
        <el-button type="primary"
                   @click="addPartmentConfirm">确 定 </el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "departmentManage",
  data() {
    return {
      memberTable: [],
      departmentMessage: {
        partmentId: -1,
        partmentName: "",
        partmentManager: "",
        partmentDescription: "",
        moblie: "",
      },
      addPartmentVisible: false,
      addNumberVisible: false,
      catalogue: [],
      teacher: "",
      teacherList: [],
      addPartmentForm: {
        partmentName: "",
        partmentManager: null,
        partmentManagerId: "",
        partmentDescription: "",
        parentId: "",
      },
    };
  },
  created() {
    this.loadDepartment();
    this.loadAllTeacher();
  },
  methods: {
    loadDepartment() {
      const that = this;
      this.$post("/media/queryGroup.do", {})
        .then((res) => {
          that.catalogue = res.data;
          if ((that.departmentMessage.partmentId = -1)) {
            that.departmentMessage.partmentName = res.data[0].name;
            that.departmentMessage.partmentManager = res.data[0].minister_name;
            that.departmentMessage.partmentDescription = res.data[0].remark;
            that.departmentMessage.partmentId = res.data[0].id;
            that.departmentMessage.moblie = res.data[0].gmobile;
            that.memberTable = res.data[0].mList;
          }
        })
        .catch((err) => {
          that.$message({
            type: "warning",
            message: err.message,
          });
        });
    },
    clickPartment(data) {
      this.departmentMessage.partmentName = data.name;
      this.departmentMessage.partmentManager = data.minister_name;
      this.departmentMessage.partmentDescription = data.remark;
      this.departmentMessage.partmentId = data.id;
      this.departmentMessage.moblie = data.gmobile;
      this.memberTable = data.mList;
    },
    // 查询教师
    loadAllTeacher() {
      const that = this;
      let data = { is_no: 1 }; //查询身份是否校内，1是校内
      if (this.teacher) {
        data.name = this.teacher;
      }
      this.$post("/media/queryAllManager.do", data)
        .then((res) => {
          that.teacherList = res.data;
        })
        .catch((err) => {
          that.$message({
            type: "warning",
            message: err.message,
          });
        });
    },
    addPartment(data) {
      this.addPartmentVisible = true;
      this.$nextTick(() => {
        this.$refs["addPartmentForm"].resetFields();
        this.addPartmentForm.parentId = data.id;
      });
    },
    // 添加和修改部门方法：
    addPartmentConfirm() {
      const that = this;
      let data = {
        name: this.addPartmentForm.partmentName,
        pid: this.addPartmentForm.parentId,
        remark: this.addPartmentForm.partmentDescription,
        minister: this.addPartmentForm.partmentManager.id,
      };
      let aipUrl = "/media/insertGroup.do";
      if (this.addPartmentForm.partmentId) {
        data.id = this.addPartmentForm.partmentId;
        aipUrl = "/media/updateGroup.do";
      }
      this.$confirm("是否确认该操作", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$post(aipUrl, data)
            .then((res) => {
              that.addPartmentVisible = false;
              that.$message({
                type: "success",
                message: "操作成功!",
              });
              that.departmentMessage.partmentId = res.data;
              that.departmentMessage.partmentName =
                that.addPartmentForm.partmentName;
              that.departmentMessage.partmentManager =
                that.addPartmentForm.partmentManager.name;
              that.departmentMessage.partmentDescription =
                that.addPartmentForm.partmentDescription;
              that.loadDepartment();
            })
            .catch((err) => {
              that.$message.error(err.message);
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消操作",
          });
        });
    },
    modifyPartment(data) {
      this.addPartmentVisible = true;
      this.$nextTick(() => {
        let manage = { name: data.minister_name, id: data.minister };
        this.addPartmentForm.partmentName = data.name;
        this.addPartmentForm.partmentId = data.id;
        this.addPartmentForm.partmentManager = manage;
        this.addPartmentForm.partmentDescription = data.remark;
      });
    },
    removePartment(data) {
      const that = this;
      this.$confirm(
        "确认删除部门吗？此操作会把该部门下的所有子部门和部门教师一并删除，请谨慎操作！",
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      )
        .then(() => {
          this.$post("/media/deleteGroup.do", { id: data.id })
            .then((res) => {
              that.$message({
                type: "success",
                message: "删除成功!",
              });
              this.loadDepartment();
            })
            .catch((err) => {
              that.$message.error(err.message);
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消操作",
          });
        });
    },
    addNumber() {
      const that = this;
      this.$post("/media/queryAllManager.do", {})
        .then((res) => {})
        .catch((err) => {
          that.$message({
            type: "warning",
            message: err.message,
          });
        });
    },
    deletesTeacher(index, teacherMessage) {
      const that = this;
      this.$confirm("是否删除该成员", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$post("/media/deleteGroupManager.do", {
            gid: teacherMessage.gid,
            managerid: teacherMessage.id,
          })
            .then((res) => {
              that.memberTable.splice(index, 1);
              that.$message({
                type: "success",
                message: "删除成功!",
              });
              this.loadDepartment();
            })
            .catch((err) => {
              that.$message.error(err.message);
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消操作",
          });
        });
    },
    addMember() {
      this.addNumberVisible = true;
      this.loadAllTeacher();
    },
    addNumberClick(id) {
      const that = this;
      this.$prompt("请输入成员职务", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(({ value }) => {
          this.$post("/media/insertGroupManager.do", {
            gid: this.departmentMessage.partmentId,
            managerid: id,
            job: value,
          })
            .then((res) => {
              that.addNumberVisible = false;
              that.$message({
                type: "success",
                message: "添加成功!",
              });
              that.loadDepartment();
            })
            .catch((err) => {
              that.$message.error(err.message);
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消添加",
          });
        });
    },
    dialogClose() {
      this.teacher = "";
    },
    renderContent(h, { node, data, store }) {
      return (
        <span on-click={() => this.clickPartment(data)}>
          <span>{node.label}</span>
          <span class="department-tree">
            <el-link
              icon="el-icon-plus"
              type="success"
              style="margin-left:15px"
              on-click={() => this.addPartment(data)}
            ></el-link>
            <el-link
              type="primary"
              icon="el-icon-edit"
              style="margin-left:15px"
              on-click={() => this.modifyPartment(data)}
            ></el-link>
            <el-link
              icon="el-icon-delete"
              type="danger"
              style="margin-left:15px"
              on-click={() => this.removePartment(data)}
            ></el-link>
          </span>
        </span>
      );
    },
  },
};
</script>

<style>
.department-tree {
  display: none;
}
.el-tree-node__content:hover .department-tree {
  display: inline;
}
</style>

<style scoped lang="scss">
.el-tree {
  position: absolute;
  top: 158px;
  width: 250px;
}
.right-box {
  margin-left: 300px;
}
.button-box {
  margin-bottom: 20px;
  margin-top: 15px;
}
.input-box {
  margin-left: 20px;
  width: 600px;
}
.dialog-box {
  display: flex;
  flex-wrap: wrap;
  font-size: 16px;
  height: 500px;
  overflow-x: auto;
}
.teacher-box {
  display: flex;
  justify-content: space-between;
  width: 300px;
  margin-left: 15px;
  align-items: baseline;
}
.department-box {
  font-size: 16px;
}
.department-box > div {
  margin-bottom: 5px;
}
</style>
